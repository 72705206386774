<template>
  <div>
    <AppHeader
      :actions="['exit']"
      text="Erreur"
      inverse
    />
    <main>
      <p class="error">
        Widget mal configuré
        <br>
        (clientId manquant)
      </p>
    </main>
  </div>
</template>

<script>
import AppHeader from '../../components/layout/Header'

export default {
  layout: 'errors',
  components: { AppHeader },
}
</script>

<style scoped>
.error {
  text-align: center;
  margin-top: 2rem;
}
</style>
