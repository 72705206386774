<template>
  <header
    class="header"
    :class="{ 'header--tall': tall }"
  >
    <nav
      class="header__nav"
      :class="{
        'header__nav--tall': tall,
        'header__nav--inverse': inverse || !hasHeaderBackgroundImg,
        'header__nav--shadow': hasShadow || !hasHeaderBackgroundImg,
      }"
      :style="headerNavStyle"
    >
      <div class="header__nav__left">
        <div
          v-if="hasHeaderBackgroundImg"
          class="header__card"
        >
          <img :src="cardPath">
        </div>
        <div
          v-if="hasButtonBack"
          class="header__button header__button--back"
        >
          <a class="header__link" @click="$router.go(-1)">
            <img :src="imgButtonBack">
          </a>
        </div>
      </div>

      <div class="header__nav__body" :class="{ 'header__nav__body--uppercase': textUppercase }">
        <div v-if="text">
          {{ text }}
        </div>
        <slot
          v-else-if="hasSlotContent"
          name="content"
        />
        <div v-else-if="hasCard && pointsPresent">
          <span class="header__nav__body__pts">
            {{ points }}
          </span>
          {{ points >= 2 ? 'pts' : 'pt' }}
        </div>
      </div>

      <div class="header__nav__right">
        <div
          v-if="hasSeparator"
          class="header__separator"
        >
          <img :src="imgSeparator">
        </div>
        <div
          v-if="hasButtonMenu"
          class="header__button header__button--menu"
        >
          <nuxt-link class="header__link" to="/menu">
            <img :src="imgButtonMenu">
          </nuxt-link>
        </div>
        <div
          v-if="hasButtonExit"
          class="header__button header__button--exit"
        >
          <a class="header__link" @click="closeApplet">
            <img :src="imgButtonExit">
          </a>
        </div>
      </div>
    </nav>

    <div
      v-if="tall"
      class="header__bottom"
      :style="tallStyle"
    >
      <img
        :src="backgroundPath"
        class="header__background"
      >
    </div>
  </header>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import MixinCloseApplet from '~/mixins/closeApplet'
export default {
  mixins: [MixinCloseApplet],
  props: {
    hasShadow: {
      type: Boolean,
      default: false,
    },
    noSeparator: {
      type: Boolean,
      default: false,
    },
    tall: {
      type: Boolean,
      default: false,
    },
    tallStyle: {
      type: Object,
      default: () => ({}),
    },
    inverse: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Array,
      required: true,
    },

    text: {
      type: String,
      default: null,
    },
    textUppercase: {
      type: Boolean,
      default: false,
    },

    backgroundColor: {
      type: String,
      default: null,
    },
  },
  computed: {
    ...mapState('server', ['points', 'headerAsset3xUrl']),
    ...mapGetters('server', ['programSlug']),
    ...mapGetters('card', ['hasCard']),

    headerNavStyle () {
      if (this.backgroundColor) { return { backgroundColor: this.backgroundColor } }
      if (!this.backgroundPath) { return }

      return {
        backgroundImage: `url(${this.backgroundPath})`,
        backgroundSize: '100% auto',
      }
    },
    // TODO: use only one simple method to get assets
    // (atm we use clientId + programName)
    backgroundPath () {
      if (!this.tall) { return }
      if (!this.programSlug) { return }

      if (this.headerAsset3xUrl) {
        return (this.headerAsset3xUrl)
      }

      try {
        return require(`assets/images/by_partner/bg-header/${this.programSlug}/bg-header@3x.jpg`)
      } catch (e) {
        if (!e.message.startsWith('Cannot find module')) { throw e }

        return undefined
      }
    },
    cardPath () {
      if (!this.programSlug) { return }

      try {
        return require(`assets/images/by_partner/img-card/${this.programSlug}/img-card.png`)
      } catch (e) {
        if (!e.message.startsWith('Cannot find module')) { throw e }

        return undefined
      }
    },
    hasHeaderBackgroundImg () {
      return !!this.backgroundPath
    },
    hasSlotContent () {
      return this.$slots['content'] && this.$slots['content'].length !== 0
    },
    pointsPresent () {
      return this.points || this.points === 0
    },
    hasSeparator () {
      return !this.noSeparator && (this.hasButtonMenu || this.hasButtonExit)
    },
    hasButtonBack () {
      return this.actions.includes('back')
    },
    hasButtonMenu () {
      return this.actions.includes('menu')
    },
    hasButtonExit () {
      return this.actions.includes('exit')
    },
    imgSeparator () {
      return (this.inverse || !this.hasHeaderBackgroundImg)
        ? require('assets/images/icons/icon-separator-inverse/icon-separator-inverse.png')
        : require('assets/images/icons/icon-separator/icon-separator.png')
    },
    imgButtonMenu () {
      return (this.inverse || !this.hasHeaderBackgroundImg)
        ? require('assets/images/icons/icon-menu-inverse/icon-menu-inverse.png')
        : require('assets/images/icons/icon-menu/icon-menu.png')
    },
    imgButtonExit () {
      return (this.inverse || !this.hasHeaderBackgroundImg)
        ? require('assets/images/icons/icon-close-inverse/icon-close-inverse.png')
        : require('assets/images/icons/icon-close/icon-close.png')
    },
    imgButtonBack () {
      return (this.inverse || !this.hasHeaderBackgroundImg)
        ? require('assets/images/icons/icon-back-inverse/icon-back-inverse.png')
        : require('assets/images/icons/icon-back/icon-back.png')
    },
  },
  mounted () {
    let height = this.hasHeaderBackgroundImg ? 0 : 5
    this.$store.commit('layout/setHeaderHeight', height + 'rem')
  },
  destroyed () {
    this.$store.commit('layout/setHeaderHeight', null)
  },
}
</script>

<style lang="scss" scoped>
@import "~assets/css/mixins";
@import "~assets/css/variables";

$nav-tall-padding-x: 1rem;

// .header {
// }
.header__nav {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  z-index: $z-index-header;
  text-align: center;
  color: white;

  padding: 0 1.5rem;
}
.header__nav--tall {
  padding: 0 $nav-tall-padding-x;
}
.header__nav--shadow {
  box-shadow: 0 0.1rem 0.1rem rgba(0,0,0,0.3);
}
.header__nav--inverse {
  background-color: white;
  color: black;
}

.header__button--back, .header__button--menu, .header__button--exit {
  cursor: pointer;
}

.header__nav__left {
  @include abso-v-center;
  left: $nav-tall-padding-x;
}
.header__nav__right {
  @include abso-v-center;
  right: $nav-tall-padding-x;

  display: flex;
}

.header__nav__body {
  height: 5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 2rem;
}
.header__nav__body--uppercase {
  text-transform: uppercase;
}

.header__nav__body__pts {
  font-size: 3.2rem;
}

.header__separator {
  margin-right: 1rem;
}
.header__button--menu {
  margin-right: 1rem;
}

.header__background {
  width: 100%;
  height: auto;
}

// vertically center images
.header__link, .header__separator {
  display: flex;
}
</style>
